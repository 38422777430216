
export function telegramInit(){
  window.Telegram.WebApp.ready();
}

export function telegramGetUserInfo(){
  return window.Telegram.WebApp.initDataUnsafe.user;
}

export function telegramGetColorScheme(){
  return window.Telegram.WebApp.colorScheme;
}

export function telegramSetBackButtonVisible(visible){
  window.Telegram.WebApp.BackButton.isVisible = visible;
}

export function telegramOnEventBackBtnClicked(callback){
  window.Telegram.WebApp.onEvent('backButtonClicked',() => {
    callback && callback()
  })
}



