<template>
  <div class="page">
    <!-- <NavBar :title="'KTX Finance'" :left-arrow="true" /> -->
    
    <div class="main-container">

      <div class="main-title">
        <div>KTX Finance</div>
        <div class="user-name">{{ telegramUserInfo ? telegramUserInfo.first_name+' '+telegramUserInfo.last_name : '' }}</div>
      </div>
      <div class="description">Trade and earn cryptocurrencies with lowest fees,depthless liquidity,and up to 50x leverage.Generate yield in a bull,bear,or sideways market.</div>

      <div class="btn-box">
        <!-- <img src="@/assets/images/btn_trade.png" @click="$router.push({path:'/task'})">
        <img src="@/assets/images/btn_notifi.png" @click="$router.push({path:'/notifi'})">
        <img src="@/assets/images/btn_settings.png" @click="$router.push({path:'/settings'})"> -->
        <div class="btn" @click="onJumpToTradePage">
          <svg-icon name="Money-exchange" style="font-size: 16px;" />
          <div>Trade</div>
        </div>
        <div class="btn" @click="$router.push({path:'/notifi'})">
          <svg-icon name="Bell" style="font-size: 16px;" />
          <div>Notifi</div>
        </div>
        <div class="btn" @click="$router.push({path:'/settings'})">
          <svg-icon name="Setting" style="font-size: 16px;" />
          <div>Setting</div>
        </div>

      </div>

      <div class="more">
        <div class="more-left">
          <van-icon name="question" size="34" />
          <div class="more-data">
            <div class="number">$80,000</div>
            <div class="tips">worth of prizes!</div>
          </div>
        </div>
        <div class="more-btn" @click="$router.push({path:'/task'})">Go</div>
      </div>

      <div class="title">Social Accounts</div>
      <div class="list">

        <div v-for="item in dataList" class="item" @click="item.click">
          <div class="left">
            <div class="icon">
              <!-- <van-icon :name="item.leftIcon" size="26" /> -->
              <svg-icon :name="item.leftIcon" style="font-size: 20px;" />
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
          <div class="right">
            <van-icon v-if="item.rightIcon" :name="item.rightIcon" size="24" />
            <van-icon name="arrow" size="22" />
          </div>
        </div>
      </div>

      <div class="title">FAQ</div>

    </div>

    <walletSelectPop :visible="walletSelectPopupVisible" :web3Modal="wcWeb3Modal" @removePopup="walletSelectPopupRemove" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import {  themeInit, setTheme } from '@/utils/changeTheme';
import { Icon as VanIcon, Button as VanButton} from "vant";
import walletSelectPop from "./walletSelectPop.vue";
import {  
  telegramInit, 
  telegramGetUserInfo, 
  telegramGetColorScheme, 
  telegramSetBackButtonVisible 
} from '@/utils/telegram';

import {  
  wcGetWeb3Modal, 
  wcWatchAccount, 
} from '@/utils/walletConnect';


// telegram
telegramInit();
telegramSetBackButtonVisible(false)
setTheme(telegramGetColorScheme())
const telegramUserInfo = ref({ 
  first_name: '',
  last_name: '',
  username: '',
})
telegramUserInfo.value = telegramGetUserInfo();

const onJumpToTradePage = () => {
  window.location.href = "https://app.ktx.finance/#/trade";
}
const onDefaultClick = () => {

}
const address = ref('')
const setAddress = (account) => {
  if(account && account.address){
    address.value = account.address.substring(0,5) + '...'+account.address.substring(account.address.length-5,account.address.length)
  }else{
    address.value = 'Connect Wallet'
  }
  dataList.value[0].text = address
}

// WalletConnect
const wcWeb3Modal = reactive(wcGetWeb3Modal());
const onConnectWallet = () => {
  walletSelectPopupVisible.value = true
  return

  wcWeb3Modal.openModal()
}
wcWatchAccount((account) => {
  setAddress(account)
})


const dataList = ref([
  { leftIcon: 'Wallet', text: 'Connect Wallet', rightIcon: '', click: onConnectWallet},
  { leftIcon: 'Google', text: 'Google', rightIcon: '', click: onDefaultClick},
  { leftIcon: 'Twitter', text: 'Add Twitter', rightIcon: '', click: onDefaultClick},
  { leftIcon: 'Discord', text: 'Add Discord', rightIcon: '', click: onDefaultClick},
])

const walletSelectPopupVisible = ref(false)
const walletSelectPopupRemove = () =>{
  walletSelectPopupVisible.value = false
}

onMounted(() => {
  themeInit();
});
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  .main-container{
    width: 90%;
    margin: 0 auto;
    padding: 30px 0 0 0;
    text-align: left;
    .main-title{
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-name{
        font-size: 14px;
        font-weight: normal;
      }
    }
    .description{
      font-size: 14px;
      line-height: 24px;
      margin: 25px 0;
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
    .btn-box{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: 27%;
        cursor: pointer;
      }
      .btn{
        width: 27%;
        padding: 10px 0;
        // background-color: #323842;
        background-color: var(--main-btn-bg-color);
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        div{
          margin-left: 5px;
        }
      }
    }
    .more{
      margin: 20px auto;
      padding: 20px;
      box-sizing: border-box;
      background-color: var(--main-block-bg-color);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .more-left{
        display: flex;
        align-items: center;
        .more-data{
          line-height: 24px;
          margin-left: 10px;
          .number{
            font-size: 14px;
            text-align: left;
            span{
              font-weight: bold;
              font-size: 16px;
            }
          }
          .tips{
            font-size: 14px;
          }
        }
      }
      .more-btn{
        height: 22px;
        line-height: 22px;
        padding: 0 10px;
        background-color: var(--main-btn-bg-color);
        color: #ffffff;
        font-size: 12px;
        border-radius: 11px;
      }
    }
    .list{
      width: 90%;
      margin: 0 auto;
      .line{
        width: 100%;
        height: 1px;
        background-color: #DEE1E6FF;
      }
      .item{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        .left{
          display: flex;
          align-items: center;
          .icon{
            width: 30px;
            margin-right: 15px;
          }
          .text{
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
        }
        .right{
          display: flex;
          align-items: center;
          .text{
            font-size: 14px;
            line-height: 20px;
            text-align: right;
          }
        }
        
      }
    }
  }
}
:deep(.van-nav-bar .van-icon) {
    color: #333333;
}
</style>
