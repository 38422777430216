import { showNotify, closeNotify } from 'vant';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { configureChains, createConfig, connect, disconnect  } from '@wagmi/core'
import { getAccount, getContract, watchAccount, signMessage  } from '@wagmi/core'
import { arbitrum, mainnet, optimism, polygon } from '@wagmi/core/chains'
// import { alchemyProvider } from 'wagmi/providers/alchemy'
// import { publicProvider } from 'wagmi/providers/public'

import { InjectedConnector } from '@wagmi/core/connectors/injected'
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet'
import { LedgerConnector } from '@wagmi/connectors/ledger'
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask'
import { MockConnector } from '@wagmi/core/connectors/mock'
import { SafeConnector } from '@wagmi/connectors/safe'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import { WalletConnectLegacyConnector } from '@wagmi/core/connectors/walletConnectLegacy'






const projectId = process.env.VUE_APP_WALLET_CONNECT_PROJECT_ID
const { chains, publicClient, provider, webSocketProvider } = configureChains(
  [arbitrum, mainnet, optimism, polygon], 
  [w3mProvider({ projectId })]
)

export function wcGetWeb3Modal(){
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
  })
  const ethereumClient = new EthereumClient(wagmiConfig, chains)
  const web3modal = new Web3Modal({ projectId }, ethereumClient)
  return web3modal;
}

export function wcWatchAccount(callback){
  const unwatch = watchAccount((account) => {
    callback && callback(account)
  })
}

export function wcGetAccount(){
  return getAccount()
}

export async function wcSendSign(message){
  const signature = await signMessage({
    message: message,
  })
  console.log('signature',signature);
  return signature
}

// 连接Coinbase
export async function wcCoinbaseConnect(){
  const connector = new CoinbaseWalletConnector({
    chains: [mainnet, optimism, polygon],
    options: {
      appName: 'CoinbaseWallet',//'wagmi.sh',
      jsonRpcUrl: 'https://eth-mainnet.g.alchemy.com/v2/uKVT5D2AdRSwLvHH9545q1lc1WBshgfN' //'https://eth-mainnet.alchemyapi.io/v2/uKVT5D2AdRSwLvHH9545q1lc1WBshgfN',
    },
  })

  console.log(`wcCoinbaseConnect connector`,connector);

  wcConnectSend(connector)
}

// 连接Ledger
export async function wcLedgerConnect(){
  // Ledger + WalletConnect v2
  const connector = new LedgerConnector({
    chains: [mainnet],
    projectId: projectId,
  })

  // WalletConnect v1
  // const connector = new LedgerConnector({
  //   chains: [mainnet],
  // })
  console.log(`wcLedgerConnect connector`,connector);

  wcConnectSend(connector)
}

// 连接MetaMask
export async function wcMetaMaskConnect(){
  const connector = new MetaMaskConnector({
    chains: chains,//[mainnet, optimism, polygon],
    options: {
      shimDisconnect: true,
      UNSTABLE_shimOnConnectSelectAccount: true,
    },
  })
  
  console.log(`wcMetaMaskConnect connector`,connector);
  wcConnectSend(connector)
}

// 连接Mock
export async function wcMockConnect(){
  const connector = new MockConnector({
    chains: chains,//[mainnet, optimism],
  })
  console.log(`wcMockConnect connector`,connector);

  wcConnectSend(connector)
}

// 连接Safe
export async function wcSafeConnect(){
  const connector = new SafeConnector({
    chains: chains,//[mainnet, optimism],
    options: {
      allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
      debug: false,
    },
  })
  console.log(`wcSafeConnect connector`,connector);

  wcConnectSend(connector)
}

// 连接WalletConnect
export async function wcWalletConnectConnect(){
  const connector = new WalletConnectConnector({
    chains: chains,//[mainnet, optimism, polygon],
    options: {
      projectId: projectId,
    },
  })
  console.log(`wcWalletConnectConnect connector`,connector);

  wcConnectSend(connector)
}

// 连接WalletConnect Legacy
export async function wcWalletConnectLegacyConnect(){
  const connector = new WalletConnectLegacyConnector({
    chains: chains,//[mainnet, optimism, polygon],
    options: {
      qrcode: true,
    },
  })
  console.log(`wcWalletConnectLegacyConnect connector`,connector);

  wcConnectSend(connector)
}

// 连接imToken
export async function wcImTokenConnect(){
  const connector = new InjectedConnector({
    chains: chains,//[mainnet, optimism, polygon],
    options: {
      name: 'ImToken',
      getProvider: () => window !== 'undefined' ? window.isImToken : undefined
    },
  })
  console.log(`wcImTokenConnect connector`,connector);

  wcConnectSend(connector)
}

export async function wcInjectedConnect(){
  const connector = new InjectedConnector({
    chains: chains,//[mainnet, optimism, polygon],
    options: {
      name: 'Injected'
    },
  })
  console.log(`wcInjectedConnect connector`,connector);

  wcConnectSend(connector)
}

async function wcConnectSend(connector){
  try {
    // connector = new InjectedConnector()
    const result = await connect({
      connector: connector//new InjectedConnector(),
    })
    console.log(`wcConnectSend sresult`,result);
  } catch (err) {
    let msg = err.shortMessage ? err.shortMessage : (err.message ? err.message : 'error')
    showNotify({
      message: msg,
      type: 'warning',
      duration: 2000,
      position: 'bottom',
      background: 'rgb(0, 0, 0, 0.6)',
      color: '#ffffff'
    });
    console.log(`wcConnectSend err`,err);
  }
}


