

const req = require.context('./svg', false, /\.svg$/)
const requireAll = (requireContext) => requireContext.keys().map(requireContext)
requireAll(req)

// const iconList = req.keys().map((item) => req(item))
// export function getNameList() {
//   return iconList.map((item) => item.default.id.split('-')[1])
// }


// const iconList = []
// const files = import.meta.globEager('./svg/*.svg')
// Object.keys(files).forEach((path) => { 
//   var pos = path.lastIndexOf('/');
//   let tempPath = path.substring(pos + 1);
//   let fileName = tempPath.substring(0, tempPath.lastIndexOf("."));
//   iconList.push(fileName)
// })
// export function getNameList() {
//   return iconList
// }