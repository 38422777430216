<template>
  <van-popup
    v-model:show="visible"
    round
    position="bottom"
    :style="{ height: '50%' }"
    closeable
    close-icon-position="top-right"
    close-icon="close"
    :z-index="80"
    :before-close="onBeforeClose"
  >
    <div class="title">Connect a wallet</div>
    <van-grid class="grid" :border="false" :column-num="4" :gutter="0">
      <van-grid-item v-for="item in dataList" @click="item.click">
        <van-image :src="item.logo" width="85%" />
        <div class="name">{{ item.name }}</div>
      </van-grid-item>
    </van-grid>

    <div class="faq">
      <h4>What is a Wallet?</h4>
      <p>A wallet is used to send,receive,store,and display digital assets. It's also a new way to log in.without needing to create new accounts and passwords on every website.</p>
    </div>

    <div class="btns">
      <div class="btn">Get a Wallet</div>
      <div class="btn">Learn More</div>
    </div>

  </van-popup>
</template>

<script setup>
import { ref, reactive, onMounted, watch, defineEmits, defineProps } from "vue";
import { Popup as VanPopup, Grid as VanGrid, GridItem as VanGridItem, Image as VanImage } from "vant";
import metaMaskImg from  "@/assets/images/wallet/metamask.png"
import coinbaseWalletImg from  "@/assets/images/wallet/coinbaseWallet.png"
import ledgerImg from  "@/assets/images/wallet/ledger.png"
import walletConnectImg from  "@/assets/images/wallet/walletconnect.png"
import imTokenImg from  "@/assets/images/wallet/imToken.png"

import {  
  wcGetWeb3Modal, 
  wcWatchAccount, 
  wcGetAccount,
  wcSendSign,

  wcCoinbaseConnect,
  wcLedgerConnect,
  wcMetaMaskConnect, 
  wcMockConnect,
  wcSafeConnect,
  wcWalletConnectConnect,
  wcWalletConnectLegacyConnect,
  wcImTokenConnect,
  wcInjectedConnect,
} from '@/utils/walletConnect';

const em = defineEmits(["removePopup"]);
const props = defineProps({
  visible: { type: Boolean, default: false },
  web3Modal: { type: Object, default: {}}
});

const visible = ref(false)
const walletConnectPopupShow = () => {
  props.web3Modal && props.web3Modal.openModal()
}
const dataList = ref([
  { name: 'MetaMask', logo: metaMaskImg, click: wcMetaMaskConnect},
  { name: 'Coinbase', logo: coinbaseWalletImg, click: wcCoinbaseConnect},
  { name: 'Ledger', logo: ledgerImg, click: wcLedgerConnect},
  { name: 'WalletConnect', logo: walletConnectImg, click: walletConnectPopupShow},
  // { name: 'imToken', logo: imTokenImg, click: wcImTokenConnect},
])

watch(
  () => [props.visible],
  (newVal, oldVal) => {
    visible.value = newVal[0];
    if(!visible.value){
      return
    }
  }
);

const onBeforeClose = () =>{
  visible.value = false
  em('removePopup')
}

onMounted(() => {
  
});

</script>

<style lang="scss" scoped>
.title{
  font-size: 18px;
  padding: 18px 0;
}
.grid{
  width: 100%;
  .name{
      // width: 100%;
      font-size: 12px;
      padding: 5px 0 0 0;
    }
}
.faq{
  padding: 20px 0;
  h4{
    padding: 0 0 15px 0;
  }
  p{
    line-height: 20px;
  }
}
.btns{
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn{
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    background-color: var(--main-btn-bg-color);
    color: #ffffff;
    font-size: 12px;
    border-radius: 16px;
    margin: 0 15px;
  }
}
</style>
