import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/main.scss'
import './assets/css/main.css'
import 'vant/lib/index.css'

import SvgIcon from '@/components/SvgIcon' // svg组件

import './icons'

const app = createApp(App)

app.component('svg-icon', SvgIcon)

app
    .use(store)
    .use(router)
    .mount('#app')
