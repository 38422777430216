
import { getLocalStore, setLocalStore } from "@/utils/localStore";

// 动态修改自动定义颜色
export const setCssVar = (prop, val, dom = document.documentElement) => {
  dom.style.setProperty(prop, val)
}

// themeType: light,dark
export const setTheme = (themeType) => {
  if(themeType == 'light'){
    setCssVar('--main-bg-color', '#ffffff')
    setCssVar('--main-font-color', '#000000')
    setCssVar('--main-block-bg-color', '#f2f3f8')

    setCssVar('--van-switch-background', 'rgba(120, 120, 128, .16)')
    setCssVar('--van-switch-node-background', '#ffffff')
    setCssVar('--van-switch-on-background', '#1989fa')

    setCssVar('--main-btn-bg-color', '#4e4e4e')

    setCssVar('--van-popup-background', '#ffffff')
    setCssVar('--van-grid-item-content-background', '#ffffff')

    setLocalStore("theme", 'light');
  }else if(themeType == 'dark'){
    setCssVar('--main-bg-color', '#1D2733')
    setCssVar('--main-font-color', '#ffffff')
    setCssVar('--main-block-bg-color', '#444444')

    setCssVar('--van-switch-background', '#444444')
    setCssVar('--van-switch-node-background', '#ffffff')
    setCssVar('--van-switch-on-background', '#eeeeee')

    setCssVar('--main-btn-bg-color', '#666666')

    setCssVar('--van-popup-background', '#1D2733')
    setCssVar('--van-grid-item-content-background', '#1D2733')

    setLocalStore("theme", 'dark');
  }
}

export const themeInit = () => {
  let themeType = getLocalStore("theme");
  if(themeType == 'light'){
    setTheme('light')
  }else if(themeType == 'dark'){
    setTheme('dark')
  }
}