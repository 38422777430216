import { createRouter, createWebHashHistory } from 'vue-router'
import Explore from '../views/Explore.vue'

const routes = [
  {
    path: '/',
    name: 'explore',
    component: Explore
  },
  {
    path: '/task',
    name: 'task',
    component: () => import(/* webpackChunkName: "task" */ '../views/Task.vue')
  },
  {
    path: '/notifi',
    name: 'notifi',
    component: () => import(/* webpackChunkName: "notifi" */ '../views/Notifi.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
